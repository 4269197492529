/* Scss Document */
@charset 'UTF-8';
$main-cool-color: #336600; //59b200
$main-warm-color: #eb5f00; //ff9400
$main-dark-color: #006834;
$main-bdr-color: #dddddd;


.ranking,
.camBnr {
	display: none;
}


.ec-layoutRole__main {
	font-family: Avenir, '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	text-align: left;
	font-size: 116.666666%;
	line-height: 1.5;

	img {
		margin-bottom: 0;
	}

	.slider {
		margin-bottom: 40px;
	}

	.topNotice {
		width: 100%;
		margin: 3% auto;
		padding: 4%;
		border: #eaeaea 5px solid;
		box-sizing: border-box;
		font-size: 80%;

		strong {
			font-weight: bold;
			color: #f8002b;
		}

		h2 {
			margin-bottom: 0.5em;
			border-bottom: 3px #f5811e solid;
			font-size: 125%;
		}
	}

	.ec-topicRole,
	.area__ec-eyecatchRole {
		margin-top: 40px;

		@media screen and (min-width: 768px) {
			margin-top: 60px;
		}
	}

	.top-ranking,
	.top-itemlist {
		padding-top: 40px;

		@media screen and (min-width: 768px) {
			padding-top: 60px;
		}

		a {
			color: #333;
		}

		p {
			margin-bottom: 1em;
			font-size: 75%;
		}
	}

	/////////////////////////////
	// ランキング
	/////////////////////////////
	.top-ranking {
		p {
			br {
				display: none;
			}
		}

		.ranking-box {
			display: -webkit-flex;
			display: flex;
			-webkit-flex-flow: row wrap;
			flex-flow: row wrap;
			-webkit-justify-content: space-between;
			justify-content: space-between;
			align-items: stretch;
			counter-reset: item;

			section {
				width: 48%;
				margin-bottom: 10px;
				background-color: #fff;
				border-radius: 10px;
				border: 2px solid $main-bdr-color;
				overflow: hidden;

				.ranking-photo {
					border-radius: 10px 10px 0 0;
					background: #fffaea;
					background: radial-gradient(#ffffff, #fffaea);
					text-align: center;

					img {
						width: 75%;
					}
				}

				h3 {
					margin: 0;
					padding: 1em 4% 0.5em calc(4% + 2em);
					font-weight: bold;
					font-size: 100%;
					line-height: 1;
					position: relative;
					min-height: 2em;

					&:before {
						counter-increment: item;
						content: counter(item);
						color: #fff;
						background-color: #e3ba18;
						font-size: 115%;
						line-height: 1.6;
						border-radius: .75em;
						width: 1.5em;
						height: 1.5em;
						display: block;
						position: absolute;
						margin: auto;
						left: .5em;
						top: 0;
						bottom: 0;
						text-align: center;
					}
				}

				&.rank02 {
					h3:before {
						background-color: #b9c5d1;
					}
				}

				&.rank03 {
					h3:before {
						background-color: #9f7037;
					}
				}

				&.rank04,
				&.rank05,
				&.rank06,
				&.rank07,
				&.rank08,
				&.rank09 {
					h3:before {
						background-color: #006834;
					}
				}

				&.rank09 {
					display: none;
				}

				p {
					margin-bottom: 1em;
					padding: 0 4%;
				}

				@media screen and (min-width : 1024px) {
					width: 32%;
					margin-bottom: 10px;

					&.rank01,
					&.rank02,
					&.rank03 {
						h3 {
							font-size: 112.5%;
							line-height: 1.8;
						}
					}

					&.rank04,
					&.rank05,
					&.rank06,
					&.rank07,
					&.rank08,
					&.rank09 {
						width: 15.7%;
					}

					&.rank09 {
						display: block;
					}
				}
			}
		}
	}

	/////////////////////////////
	// 商品一覧
	/////////////////////////////
	.top-itemlist {
		.area-top-itemlist {

			.readmore {
				position: relative;
				width: 100%;
				margin: 1em auto;
				display: block;
				background-color: #aaa;
				color: #fff;
				padding: 1em 0;
				line-height: 1;
				border: none;

				&::after {
					content: " ";
					position: absolute;
					width: 20px;
					height: 20px;
					border-top: solid 3px #fff;
					border-right: solid 3px #fff;
					-ms-transform: rotate(135deg);
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
					right: 0;
					left: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					// right: 28px;
					// top: 15px;
					-webkit-transition: 0.5s;
					-o-transition: 0.5s;
					transition: 0.5s;
					-erbkit-transition: 0.5s;
				}
			}

			.on-click {
				color: transparent !important;

				&:after {
					-ms-transform: rotate(-45deg);
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					top: 0.5em;
					// top: 25px;
					// left: 0;
					// right: 0;
					// margin: auto;
				}
			}

			.box-top-itemlist {


				ul {
					// display: none;

					li {
						position: relative;
						// border-bottom: 1px dotted $main-bdr-color;
						// font-size: 114.285714%;
						margin: .5em 0;

						// &:after {
						// 	position: absolute;
						// 	right: 4%;
						// 	top: 33%;
						// 	color: #666;
						// 	font-family: "Font Awesome 5 Pro";
						// 	content: "\f105";
						// 	width: 14px;
						// 	height: 14px;
						// 	font-weight: 900;
						// }

						a {
							display: block;
							// padding: 1em;
						}

						&:last-child {
							border-bottom: none;
						}
					}
				}
			}

			.box-itemlist-readmore {
				display: none;
			}
		}
	}
}

//#main
@media screen and (min-width : 768px) {
	.ec-layoutRole__main {
		font-size: 100%;

		.top-ranking {
			.ranking-box {
				section {
					width: 24%;
					margin-bottom: 20px;

				}
			}
		}
	}
}

@media screen and (min-width : 1024px) {
	.ec-layoutRole__main {
		width: 100%;
		margin-top: 0;

		.slider {
			margin-bottom: 60px;
		}

		.topNotice {
			padding: 2%;
			border: #eaeaea 10px solid;
			box-sizing: border-box;
			font-size: 100%;

			h2 {
				font-size: 200%;
			}
		}

		.top-itemlist {
			.area-top-itemlist {
				display: -webkit-flex;
				display: flex;
				-webkit-flex-flow: row wrap;
				flex-flow: row wrap;
				-webkit-justify-content: space-between;
				justify-content: space-between;
				border-bottom: none;

				.readmore {
					display: none;
				}

				.box-top-itemlist {
					width: calc(97% / 4);

					ul {
						display: block;

						li {
							width: 100%;
							border-bottom: none;
							font-size: 100%;
							list-style: disc inside;

							&:after {
								content: "";
							}

							a {
								display: inline-block;
								padding: 0.25em;
							}

							&:last-child {
								border-bottom: none;
							}
						}
					}
				}

				.box-itemlist-readmore {
					display: block;
				}
			}
		}

		.top-ranking {

			.ranking-box {
				section {
					width: 32%;
					margin-bottom: 10px;

					&.rank01,
					&.rank02,
					&.rank03 {
						h3 {
							font-size: 112.5%;
							line-height: 1.8;
						}
					}

					&.rank04,
					&.rank05,
					&.rank06,
					&.rank07,
					&.rank08,
					&.rank09 {
						width: 15.7%;
					}

					&.rank09 {
						display: block;
					}
				}
			}
		}
	}
}
