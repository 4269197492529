@charset "UTF-8";
/* Scss Document */
.ranking,
.camBnr {
  display: none;
}

.ec-layoutRole__main {
  font-family: Avenir, "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  text-align: left;
  font-size: 116.666666%;
  line-height: 1.5;
}
.ec-layoutRole__main img {
  margin-bottom: 0;
}
.ec-layoutRole__main .slider {
  margin-bottom: 40px;
}
.ec-layoutRole__main .topNotice {
  width: 100%;
  margin: 3% auto;
  padding: 4%;
  border: #eaeaea 5px solid;
  box-sizing: border-box;
  font-size: 80%;
}
.ec-layoutRole__main .topNotice strong {
  font-weight: bold;
  color: #f8002b;
}
.ec-layoutRole__main .topNotice h2 {
  margin-bottom: 0.5em;
  border-bottom: 3px #f5811e solid;
  font-size: 125%;
}
.ec-layoutRole__main .ec-topicRole,
.ec-layoutRole__main .area__ec-eyecatchRole {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .ec-layoutRole__main .ec-topicRole,
.ec-layoutRole__main .area__ec-eyecatchRole {
    margin-top: 60px;
  }
}
.ec-layoutRole__main .top-ranking,
.ec-layoutRole__main .top-itemlist {
  padding-top: 40px;
}
@media screen and (min-width: 768px) {
  .ec-layoutRole__main .top-ranking,
.ec-layoutRole__main .top-itemlist {
    padding-top: 60px;
  }
}
.ec-layoutRole__main .top-ranking a,
.ec-layoutRole__main .top-itemlist a {
  color: #333;
}
.ec-layoutRole__main .top-ranking p,
.ec-layoutRole__main .top-itemlist p {
  margin-bottom: 1em;
  font-size: 75%;
}
.ec-layoutRole__main .top-ranking p br {
  display: none;
}
.ec-layoutRole__main .top-ranking .ranking-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: stretch;
  counter-reset: item;
}
.ec-layoutRole__main .top-ranking .ranking-box section {
  width: 48%;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #dddddd;
  overflow: hidden;
}
.ec-layoutRole__main .top-ranking .ranking-box section .ranking-photo {
  border-radius: 10px 10px 0 0;
  background: #fffaea;
  background: radial-gradient(#ffffff, #fffaea);
  text-align: center;
}
.ec-layoutRole__main .top-ranking .ranking-box section .ranking-photo img {
  width: 75%;
}
.ec-layoutRole__main .top-ranking .ranking-box section h3 {
  margin: 0;
  padding: 1em 4% 0.5em calc(4% + 2em);
  font-weight: bold;
  font-size: 100%;
  line-height: 1;
  position: relative;
  min-height: 2em;
}
.ec-layoutRole__main .top-ranking .ranking-box section h3:before {
  counter-increment: item;
  content: counter(item);
  color: #fff;
  background-color: #e3ba18;
  font-size: 115%;
  line-height: 1.6;
  border-radius: 0.75em;
  width: 1.5em;
  height: 1.5em;
  display: block;
  position: absolute;
  margin: auto;
  left: 0.5em;
  top: 0;
  bottom: 0;
  text-align: center;
}
.ec-layoutRole__main .top-ranking .ranking-box section.rank02 h3:before {
  background-color: #b9c5d1;
}
.ec-layoutRole__main .top-ranking .ranking-box section.rank03 h3:before {
  background-color: #9f7037;
}
.ec-layoutRole__main .top-ranking .ranking-box section.rank04 h3:before, .ec-layoutRole__main .top-ranking .ranking-box section.rank05 h3:before, .ec-layoutRole__main .top-ranking .ranking-box section.rank06 h3:before, .ec-layoutRole__main .top-ranking .ranking-box section.rank07 h3:before, .ec-layoutRole__main .top-ranking .ranking-box section.rank08 h3:before, .ec-layoutRole__main .top-ranking .ranking-box section.rank09 h3:before {
  background-color: #006834;
}
.ec-layoutRole__main .top-ranking .ranking-box section.rank09 {
  display: none;
}
.ec-layoutRole__main .top-ranking .ranking-box section p {
  margin-bottom: 1em;
  padding: 0 4%;
}
@media screen and (min-width: 1024px) {
  .ec-layoutRole__main .top-ranking .ranking-box section {
    width: 32%;
    margin-bottom: 10px;
  }
  .ec-layoutRole__main .top-ranking .ranking-box section.rank01 h3, .ec-layoutRole__main .top-ranking .ranking-box section.rank02 h3, .ec-layoutRole__main .top-ranking .ranking-box section.rank03 h3 {
    font-size: 112.5%;
    line-height: 1.8;
  }
  .ec-layoutRole__main .top-ranking .ranking-box section.rank04, .ec-layoutRole__main .top-ranking .ranking-box section.rank05, .ec-layoutRole__main .top-ranking .ranking-box section.rank06, .ec-layoutRole__main .top-ranking .ranking-box section.rank07, .ec-layoutRole__main .top-ranking .ranking-box section.rank08, .ec-layoutRole__main .top-ranking .ranking-box section.rank09 {
    width: 15.7%;
  }
  .ec-layoutRole__main .top-ranking .ranking-box section.rank09 {
    display: block;
  }
}
.ec-layoutRole__main .top-itemlist .area-top-itemlist .readmore {
  position: relative;
  width: 100%;
  margin: 1em auto;
  display: block;
  background-color: #aaa;
  color: #fff;
  padding: 1em 0;
  line-height: 1;
  border: none;
}
.ec-layoutRole__main .top-itemlist .area-top-itemlist .readmore::after {
  content: " ";
  position: absolute;
  width: 20px;
  height: 20px;
  border-top: solid 3px #fff;
  border-right: solid 3px #fff;
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -erbkit-transition: 0.5s;
}
.ec-layoutRole__main .top-itemlist .area-top-itemlist .on-click {
  color: transparent !important;
}
.ec-layoutRole__main .top-itemlist .area-top-itemlist .on-click:after {
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  top: 0.5em;
}
.ec-layoutRole__main .top-itemlist .area-top-itemlist .box-top-itemlist ul li {
  position: relative;
  margin: 0.5em 0;
}
.ec-layoutRole__main .top-itemlist .area-top-itemlist .box-top-itemlist ul li a {
  display: block;
}
.ec-layoutRole__main .top-itemlist .area-top-itemlist .box-top-itemlist ul li:last-child {
  border-bottom: none;
}
.ec-layoutRole__main .top-itemlist .area-top-itemlist .box-itemlist-readmore {
  display: none;
}

@media screen and (min-width: 768px) {
  .ec-layoutRole__main {
    font-size: 100%;
  }
  .ec-layoutRole__main .top-ranking .ranking-box section {
    width: 24%;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .ec-layoutRole__main {
    width: 100%;
    margin-top: 0;
  }
  .ec-layoutRole__main .slider {
    margin-bottom: 60px;
  }
  .ec-layoutRole__main .topNotice {
    padding: 2%;
    border: #eaeaea 10px solid;
    box-sizing: border-box;
    font-size: 100%;
  }
  .ec-layoutRole__main .topNotice h2 {
    font-size: 200%;
  }
  .ec-layoutRole__main .top-itemlist .area-top-itemlist {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    border-bottom: none;
  }
  .ec-layoutRole__main .top-itemlist .area-top-itemlist .readmore {
    display: none;
  }
  .ec-layoutRole__main .top-itemlist .area-top-itemlist .box-top-itemlist {
    width: 24.25%;
  }
  .ec-layoutRole__main .top-itemlist .area-top-itemlist .box-top-itemlist ul {
    display: block;
  }
  .ec-layoutRole__main .top-itemlist .area-top-itemlist .box-top-itemlist ul li {
    width: 100%;
    border-bottom: none;
    font-size: 100%;
    list-style: disc inside;
  }
  .ec-layoutRole__main .top-itemlist .area-top-itemlist .box-top-itemlist ul li:after {
    content: "";
  }
  .ec-layoutRole__main .top-itemlist .area-top-itemlist .box-top-itemlist ul li a {
    display: inline-block;
    padding: 0.25em;
  }
  .ec-layoutRole__main .top-itemlist .area-top-itemlist .box-top-itemlist ul li:last-child {
    border-bottom: none;
  }
  .ec-layoutRole__main .top-itemlist .area-top-itemlist .box-itemlist-readmore {
    display: block;
  }
  .ec-layoutRole__main .top-ranking .ranking-box section {
    width: 32%;
    margin-bottom: 10px;
  }
  .ec-layoutRole__main .top-ranking .ranking-box section.rank01 h3, .ec-layoutRole__main .top-ranking .ranking-box section.rank02 h3, .ec-layoutRole__main .top-ranking .ranking-box section.rank03 h3 {
    font-size: 112.5%;
    line-height: 1.8;
  }
  .ec-layoutRole__main .top-ranking .ranking-box section.rank04, .ec-layoutRole__main .top-ranking .ranking-box section.rank05, .ec-layoutRole__main .top-ranking .ranking-box section.rank06, .ec-layoutRole__main .top-ranking .ranking-box section.rank07, .ec-layoutRole__main .top-ranking .ranking-box section.rank08, .ec-layoutRole__main .top-ranking .ranking-box section.rank09 {
    width: 15.7%;
  }
  .ec-layoutRole__main .top-ranking .ranking-box section.rank09 {
    display: block;
  }
}